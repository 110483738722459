import { injectPathToResponse, pathToEdgeInstance, hasAdminAccess, hasWriteAccess, getBaseUrl } from 'services';

// eslint-disable-next-line no-unused-vars
export async function getEnv(iotServerUuid) {
    return fetch(`${pathToEdgeInstance(iotServerUuid)}/braincube/apps/env`).then((response) => {
        if (!response.ok) {
            if (iotServerUuid) {
                return getEnv();
            }
            throw response;
        }
        return response.json().then((resp) => {
            return injectPathToResponse(iotServerUuid, resp);
        });
    });
}
// eslint-disable-next-line no-unused-vars
/**
 * @description
 * @export
 * @param {string} iotServerUuid
 * @param {string} access
 * @returns {Promise<{apps: Array, edgeSubPath: string}>}
 */
export async function getCoreAppsList(iotServerUuid, access) {
    return getEnv(iotServerUuid).then((res) => {
        const appsList = [];

        const { hasLocalCommands } = res;
        const appsManagerUrl = res.appsmanager;
        const supervisionUrl = res.supervision;
        const subPath = res.basePath;
        const edgeSubPath = res.edgePath;

        if (hasWriteAccess(access)) {
            appsList.push(
                { name: 'Data Flows Manager', icon: 'DataFlowsFlat', href: `${edgeSubPath}/` },
                { name: 'DFM Logs', icon: 'DfmLogsFlat', href: `${edgeSubPath}/braincube-log/` },
                { name: 'Storage Manager', icon: 'StorageManager', href: `${subPath}/storage-manager/` }
            );

            // So that it match both true and undefined/null
            if (hasLocalCommands !== false) {
                appsList.push({
                    name: 'Services Manager',
                    icon: 'ServicesManagerFlat',
                    href: `${subPath}/local-commands/iot-administration/services-manager`,
                });

                if (hasAdminAccess(access)) {
                    appsList.push({
                        name: 'IoT Server Configuration',
                        icon: 'AdministrationIot',
                        href: `${subPath}/local-commands/iot-administration/`,
                    });
                }
            }
            if (appsManagerUrl) {
                appsList.push({ name: 'Apps Manager', icon: 'AppsManager', href: appsManagerUrl });
            }
            if (supervisionUrl) {
                appsList.push({ name: 'Supervision', icon: 'EdgeSupervisionFlat', href: supervisionUrl });
            }
        }
        return {
            apps: appsList,
            edgeSubPath,
        };
    });
}

// eslint-disable-next-line no-unused-vars
export async function getAppsList(iotServerUuid) {
    return fetch(`${pathToEdgeInstance(iotServerUuid)}/braincube/apps/list`).then((response) => {
        if (response.ok) {
            return response.json();
        }
        if (iotServerUuid) {
            return getAppsList();
        }
        throw response;
    });
}

export async function getDiskPartitionsList() {
    return fetch(`${getBaseUrl()}/local-commands/machine-status/disks`).then((response) => {
        if (response.ok) {
            return response.json();
        }
        throw response;
    });
}
